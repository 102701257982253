<template>
  <div>
    <Portal to="ActionBar">
      <ActionBar>
        <template v-slot:left>
          <slot name="left" />
        </template>
        <slot />
        <template v-slot:right>
          <div class="flex items-center">
            <div class="text-xs text-right">
              <div v-if="user">{{ user.name }}</div>
              <div v-if="configs">{{ configs.branchName }}</div>
            </div>
            <button
              class="ml-2 px-2 -mr-2 focus:outline-none focus:opacity-75"
              @click="logoutModal = true"
            >
              <Icon name="logout" />
            </button>
          </div>
        </template>
      </ActionBar>
    </Portal>
    <Portal to="Modal">
      <Modal v-model="logoutModal">
        <div class="px-6 py-6 max-w-lg">
          <div class="mb-4">
            <p v-html="$t('reallyWantToLogout')" />
          </div>
          <div class="flex items-center">
            <Button class="w-full mr-2" @click="logoutModal = false">{{ $t('cancel') }}</Button>
            <Button class="w-full ml-2" primary @click="logout">{{ $t('logout') }}</Button>
          </div>
        </div>
      </Modal>
    </Portal>
  </div>
</template>
<script>
export default {
  data() {
    return {
      logoutModal: false,
    };
  },

  computed: {
    user() {
      return this.$store.state.authentication.user;
    },
    configs() {
      return this.$store.state.contract.configs;
    },
  },

  methods: {
    logout() {
      this.$store.dispatch('logout');
      this.$store.commit('resetSavedContractData');
      this.$store.commit('resetNewContract');
      this.$store.dispatch('removeSearchResults');
      this.$nextTick(() => {
        this.$router.push({ name: 'login', params: { studio: this.$route.params.studio } });
      });
    },
  },
};
</script>
