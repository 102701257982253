<template>
  <div>
    <NavbarLight>
      Mein Konto
    </NavbarLight>
    <Button primary @click="logout">Logout Demo</Button>
  </div>
</template>

<script>
import NavbarLight from '../../components/NavbarLight.vue';

export default {
  components: {
    NavbarLight,
  },

  methods: {
    logout() {
      localStorage.removeItem('SNAB_TOKEN');
      this.$router.push({ name: 'login', params: { studio: this.$route.params.studio } });
    },
  },
};
</script>
